import { Grid, Typography } from '@material-ui/core';
import React from 'react';

import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import { useGlobalStyles } from '../../utils/styles';
import { useStyles } from '../../utils/tu-instituto/styles';

const PoliticaPrivacidad = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title="Política de Privacidad de la Aplicación Móvil “APP ISSET”" />

      <div className={globalClasses.container}>
        <Header title="Política de Privacidad de la Aplicación Móvil “APP ISSET”"></Header>

        <Grid container spacing={5} className={classes.info}>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h2">1. Introducción</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align='justify' variant="body1">El <strong>Instituto de Seguridad Social del Estado de Tabasco (en adelante, "El ISSET")</strong>, ha desarrollado la Aplicación Móvil <strong>“App ISSET”, (en adelante, “La aplicación”)</strong> con el objetivo de mejorar la experiencia de nuestros derechohabientes para diversos servicios relacionados con su salud. Esta política de privacidad explica cómo recogemos, usamos, compartimos y protegemos la información de los usuarios de nuestra aplicación.</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={2}>            
            <Grid item xs={12}>
              <Typography variant="h3" component="h2">2. Información Recopilada</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="p">2.1 Información Personal y Sensible</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">La Aplicación recopila los siguientes tipos de información personal y sensible:</Typography>            
              <ul>           
              <Typography variant="body1">   
                <li>           
              	  <strong>Datos de Identificación:</strong> Nombre, número de afiliación, fotografía y otros datos que aparecen en la credencial digital.
                </li>
                <li>           
              	  <strong>Datos de Citas Médicas:</strong> Información relacionada con las citas médicas, incluyendo fechas, horas, medicamentos prescritos, especialidades y nombres de los médicos.
                </li>
                <li>           
              	  <strong>Datos de autenticación:</strong> Nombre de usuario y contraseña.
                </li>
              </Typography>
              </ul> 
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" component="h2">3. Uso de la Información</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Utilizamos la información recopilada para:</Typography>
              <ul>           
              <Typography variant="body1">   
                <li>           
                  Permitir el registro y la autenticación de los usuarios en la aplicación.
                </li>
                <li>           
                	Proveer y gestionar los servicios ofrecidos por la aplicación, como la visualización de la credencial digital y la gestión de citas médicas.
                </li>
                <li>           
                	Mejorar la funcionalidad y eficiencia de la aplicación.
                </li>
                <li>           
                	Comunicarnos con los usuarios para proporcionar soporte y resolver consultas relacionadas con la aplicación.
                </li>
              </Typography>
              </ul> 
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" component="h2">4. Base jurídica del tratamiento de los datos personales</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">El tratamiento de los datos personales de los usuarios de la Aplicación se basa en las siguientes bases jurídicas:</Typography>
              <ul>           
              <Typography variant="body1">   
                <li>           
                 	El consentimiento expreso de los usuarios.
                </li>
                <li>           
                	El cumplimiento de una obligación legal a cargo del ISSET.
                </li>
                <li>           
                	El interés legítimo del ISSET en mejorar la calidad de sus servicios.
                </li>                
              </Typography>
              </ul> 
            </Grid>            
            
            <Grid item xs={12} container spacing={2}>            
            <Grid item xs={12}>
              <Typography variant="h3" component="h2">5. Información compartida</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" component="p">Terceros</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">No compartimos la información personal y sensible de los usuarios con terceros, excepto en los siguientes casos:</Typography>
              <ul>           
              <Typography variant="body1">   
                <li>           
              	  <strong>Cumplimiento Legal:</strong> Podemos divulgar información personal y sensible si la ley lo requiere o si creemos de buena fe que dicha acción es necesaria para cumplir con procesos legales.
                </li>                
              </Typography>
              </ul> 
            </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h3" component="h2">6. Seguridad de la Información</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align='justify' variant="body1">Implementamos medidas de seguridad técnicas y organizativas apropiadas para proteger la información personal y sensible contra el acceso no autorizado, alteración, divulgación o destrucción. Esto incluye el uso de cifrado y otros métodos de seguridad avanzados.</Typography>              
            </Grid> 

            <Grid item xs={12}>
              <Typography variant="h3" component="h2">7. Retención y Eliminación de Datos</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align='justify' variant="body1">Retenemos la información personal y sensible durante el tiempo que sea necesario para proporcionar los servicios de la aplicación y cumplir con nuestras obligaciones legales. Los usuarios pueden solicitar la eliminación de su información contactándonos a través de los medios proporcionados a continuación.</Typography>              
            </Grid> 

            <Grid item xs={12}>
              <Typography variant="h3" component="h2">8. Derechos del Usuario</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Los usuarios tienen derecho a:</Typography>
              <ul>           
              <Typography variant="body1">   
                <li>           
                	Acceder a su información personal, obtener información sobre sus datos personales y cómo son tratados.
                </li>
                <li>           
                	Solicitar la corrección de información inexacta o incompleta.
                </li>
                <li>           
                	Solicitar la eliminación de su información personal cuando estos ya no sean necesarios para los fines para los que fueron recabados.
                </li>
                <li>           
                	Oponerse al procesamiento de su información personal en ciertas circunstancias, cuando el mismo se base en el interés legítimo del ISSET.
                </li>                
              </Typography>
              </ul> 
            </Grid> 

            <Grid item xs={12}>
              <Typography variant="h3" component="h2">9. Contacto</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Si tiene alguna pregunta o inquietud sobre esta política de privacidad o sobre cómo manejamos su información personal, puede contactarnos a través de:</Typography>
              <ul>           
              <Typography variant="body1">   
                <li>           
                	<strong>Correo Electrónico: </strong><a href="mailto:mesadeayuda@isset.gob.mx" target="_blank" rel="noopener noreferrer">mesadeayuda@isset.gob.mx</a>
                </li>
                <li>           
                	<strong>Teléfono:</strong> +52 9931758101
                </li>
                <li>           
                <strong>Dirección Postal:</strong> Instituto de Seguridad Social del Estado de Tabasco, con domicilio en Calle 27 de febrero No. 930 Centro Villahermosa, Centro - 86000, y con RFC ISS810101I37.
                </li>                             
              </Typography>
              </ul> 
            </Grid> 

            <Grid item xs={12}>
              <Typography variant="h3" component="h2">10. Cambios a esta Política de Privacidad</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align='justify' variant="body1">El ISSET podrá actualizar esta política de privacidad periódicamente. Le notificaremos sobre cualquier cambio mediante la publicación de la nueva política en este servicio. Le recomendamos revisar esta política de privacidad regularmente para estar informado sobre cómo protegemos su información.</Typography>
            </Grid> 

            <Grid item xs={12}>
              <Typography variant="h3" component="h2">11. Aceptación de esta Política</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align='justify' variant="body1">Al usar la Aplicación, usted acepta los términos de esta política de privacidad. Si no está de acuerdo con esta política, por favor no use nuestra aplicación.</Typography>
            </Grid> 

          </Grid>


         
        </Grid>
      </div>
    </Layout>
  );
};

export default PoliticaPrivacidad;